// extracted by mini-css-extract-plugin
export var about = "About-module--about--b9eae";
export var aboutContainer = "About-module--aboutContainer--09fac";
export var aboutInner = "About-module--aboutInner--a2cf4";
export var aboutLinks = "About-module--aboutLinks--79dc3";
export var aboutRoadMap = "About-module--aboutRoadMap--c7c9d";
export var aboutRoadMapInner = "About-module--aboutRoadMapInner--1e605";
export var aboutRoadMapInnerLine = "About-module--aboutRoadMapInnerLine--7d68b";
export var aboutRoadMapPhaseCircle = "About-module--aboutRoadMapPhaseCircle--4622c";
export var aboutRoadMapPhaseCircleTitle = "About-module--aboutRoadMapPhaseCircleTitle--8b359";
export var aboutRoadMapPhaseTwo = "About-module--aboutRoadMapPhaseTwo--a1c72";