import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { ContentfulAboutPageQuery } from "../../../types/gatsby-graphql"
import contactUsSquare from "../../images/contactUsSquare.png"
import PartnersSquare from "../../images/PartnersSquare.png"
import PhaseOneCircle from "../../images/PhaseOneCircle.png"
import PhaseTwoCircle from "../../images/PhaseTwoCircle.png"
import PhaseThreeCircle from "../../images/PhaseThreeCircle.png"
import RoadMap from "../../images/MapImg.png"
import MapMobileImg from "../../images/MapMobileImg.png"

import * as styles from "./About.module.scss"
import Seo from "../../components/Seo/seo"
import { SharedLayout } from "../../components/SharedLayout/SharedLayout"
import { Tabs } from "../../components/Tabs/Tabs"

const About = () => {
  const [width, setWidth] = useState<number | null>()
  const isMobile = width && width < 1024

  const data: ContentfulAboutPageQuery = useStaticQuery(graphql`
    query ContentfulAboutPage {
      contentfulAboutPage {
        title
        about {
          about
        }
        phaseOneList
        phaseTwoList
        phaseThreeList
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 75)
  }, [])

  useEffect(() => {
    setWidth(window.innerWidth)
    const handleSize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleSize)
    return () => {
      window.removeEventListener("resize", handleSize)
    }
  }, [width])

  return (
    <div className={styles.about}>
      <Seo title={data?.contentfulAboutPage?.title!} />
      <SharedLayout
        title="About Us" //{data?.contentfulAboutPage?.title}
        blueCenter={false}
        body={
          <>
            <div className={styles.aboutInner}>
              <ReactMarkdown>
                {data?.contentfulAboutPage?.about?.about as string}
              </ReactMarkdown>
            </div>
            <div className={styles.aboutRoadMap}>
              <h1>Roadmap</h1>
              <div className={styles.aboutRoadMapInner}>
                <div className={styles.aboutRoadMapPhaseCircle}>
                  <div className={styles.aboutRoadMapPhaseCircleTitle}>
                    <h1>Phase 1</h1>
                    <h3>Q3-Q4 2022</h3>
                  </div>
                  <img src={PhaseOneCircle} alt="Phase one circle" />
                </div>
                <ul>
                  {data?.contentfulAboutPage?.phaseOneList?.map(item => {
                    return <li>{item}</li>
                  })}
                </ul>

                <ul>
                  {data?.contentfulAboutPage?.phaseTwoList?.map(item => {
                    return <li>{item}</li>
                  })}
                </ul>
                <div className={styles.aboutRoadMapPhaseTwo}>
                  <div className={styles.aboutRoadMapPhaseCircleTitle}>
                    <h1>Phase 2</h1>
                    <h3>Q1-Q2 2023</h3>
                  </div>
                  <img src={PhaseTwoCircle} alt="Phase one circle" />
                </div>

                <div className={styles.aboutRoadMapPhaseCircle}>
                  <div className={styles.aboutRoadMapPhaseCircleTitle}>
                    <h1>Phase 3</h1>
                    <h3>Q3-Q4 2023</h3>
                  </div>
                  <img src={PhaseThreeCircle} alt="Phase one circle" />
                </div>
                <ul>
                  {data?.contentfulAboutPage?.phaseThreeList?.map(item => {
                    return <li>{item}</li>
                  })}
                </ul>

                <img
                  src={isMobile ? MapMobileImg : RoadMap}
                  alt="RoadMap"
                  className={styles.aboutRoadMapInnerLine}
                />
              </div>
            </div>
            <div className={styles.aboutLinks}>
              <Link to="/contact-us">
                <img src={contactUsSquare} alt="Contact Us" />
              </Link>
              <Link to="/partners">
                <img src={PartnersSquare} alt="Partners" />
              </Link>
            </div>
          </>
        }
      />
    </div>
  )
}

export default About
